import formatters from "./search-result-formatters";

const topLines = [
  {
    key: "description",
    formatter: formatters.plainText,
  },
  {
    key: "billingReference",
    formatter: formatters.plainText,
  },
  {
    key: "icc",
    formatter: formatters.plainText,
  },
  {
    key: "lastUsedImeiNumber",
    formatter: formatters.plainText,
  },
  {
    key: "connection",
    formatter: formatters.plainText,
  },
  {
    key: "fixedNumber",
    formatter: formatters.plainText,
  },
  {
    key: "mobileConnection",
    formatter: formatters.plainText,
  },
  {
    key: "address",
    formatter: formatters.address,
  },
];

const bottomLines = [
  {
    key: "productCategory",
    formatter: formatters.category,
  },
];

const componentLines = [
  {
    key: "description",
    formatter: formatters.plainText,
  },
  {
    key: "billingReference",
    formatter: formatters.plainText,
  },
  {
    key: "icc",
    formatter: formatters.plainText,
  },
  {
    key: "lastUsedImeiNumber",
    formatter: formatters.plainText,
  },
];

const mapLines = (mapping, result, translate) =>
  mapping
    .map((line) => ({
      label: translate("searchResult." + line.key),
      value: line.formatter(result[line.key]),
    }))
    .filter((line) => line.value != null);

export const mapSearchResult = (result, translate) => ({
  details: mapLines(topLines, result, translate)
    .concat(
      result.extraInformation
        .map(({ title, value }) => ({
          label: title,
          value,
        }))
        .filter((x) => x.value !== "")
    )
    .concat(mapLines(bottomLines, result, translate)),
  components: result.components.map((component) => ({
    title: component.title,
    user: component.user,
    component: mapLines(componentLines, component, translate),
  })),
});
