<template>
  <div data-test-id="b2b-subscription-search">
    <!--telia-p>
      selectedPageSize: {{ selectedPageSize }}<br />
      selectedPage: {{ selectedPage }}<br />
    </telia-p-->
    <div class="form-section">
      <telia-grid>
        <telia-row class="page-top-section">
          <telia-col width="12">
            <telia-skeleton
              v-if="loadingTranslations"
              class="telia-heading-skeleton"
            ></telia-skeleton>
            <telia-heading v-else tag="h1" variant="title-200">{{ t("heading") }}</telia-heading>
            <b2b-search-form
              @submitSearch="(event) => maybeTrackAndGetAllResultsForText(event.detail)"
              @failedValidation="(event) => trackFailedValidation(event)"
              :loading="loadingTranslations ? 'true' : 'false'"
              :input-label="t('mybusiness.form.label.search.subscriptions')"
              :button-label="t('mybusiness.button.search.subscriptions')"
              :validation-error-message="t('mybusiness.form.error.subscriptionSearchTextTooShort')"
              :default-text="initialSearchText"
              auto-focus="true"
            />
          </telia-col>
        </telia-row>
      </telia-grid>
    </div>
    <div class="results-section" aria-live="polite">
      <telia-grid v-if="currentStatus !== SEARCH_STATUS.NOT_STARTED && !loadingTranslations">
        <telia-row
          v-if="
            currentStatus === SEARCH_STATUS.TEXT_SEARCHING ||
            currentStatus === SEARCH_STATUS.TEXT_ERROR ||
            currentStatus === SEARCH_STATUS.TEXT_EMPTY_RESULT ||
            currentStatus === SEARCH_STATUS.TEXT_TOO_MANY_RESULTS
          "
          class="all-results-search-message__column"
        >
          <telia-col width="12" width-md="12">
            <search-message :status="currentStatus" />
          </telia-col>
        </telia-row>
        <telia-row v-else>
          <telia-col width="12" width-lg="4" class="categories__column">
            <section :aria-label="t('categoryFilters.heading')">
              <telia-heading tag="h2" class="categories__heading" variant="title-100">{{
                t("categoryFilters.heading")
              }}</telia-heading>
              <div class="categories__list">
                <div
                  v-for="category in unfilteredResults.categories"
                  :key="category.productCategory"
                >
                  <telia-checkbox
                    class="categories__checkbox"
                    :checked="selectedCategories.includes(category.productCategory)"
                    @change="
                      (event) => {
                        changeSelectedCategories(category.productCategory, event.target.checked);
                        actOnFiltersWithTracking(category.productCategory);
                      }
                    "
                    >{{
                      getCategoryDisplayName(category.productCategory, category.searchHits)
                    }}</telia-checkbox
                  >
                </div>
              </div>
            </section>
          </telia-col>
          <telia-col width="12" width-lg="8" ref="searchResults" class="search-results__column">
            <section :aria-label="t('searchResult.heading')">
              <!--heading for proper document structure (a11y) -->
              <telia-visually-hidden>
                <h2>{{ t("searchResult.heading") }}</h2>
              </telia-visually-hidden>
              <search-message
                v-if="currentStatus !== SEARCH_STATUS.SUCCESS"
                :status="currentStatus"
              />
              <!-- ref is not available until next tick, circumvent that by using a callback instead -->
              <search-results
                v-else
                :get-parent-scroll-target-callback="() => $refs.searchResults"
                :scope-id="scopeId"
                :search-text="appliedSearchText"
                :search-results="filteredResults"
                :selected-categories="selectedCategories"
                :default-page-size="selectedPageSize"
                :default-page="selectedPage"
                @change-page="(event) => saveSelectedPage(event.pageSize, event.page)"
              />
            </section>
          </telia-col>
        </telia-row>
      </telia-grid>
    </div>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "./locale";
import { logError } from "@telia/b2x-logging";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import {
  getAllSubscriptionsForText,
  filterSubscriptionsByCategory,
} from "./services/search-service";
import { getHistoryState, replaceHistoryState } from "./services/history-service";
import { getSearchText, analytics } from "@telia/b2b-search-framework";
import { getTranslatedSubCategoryDisplayName } from "@telia/b2b-product-categories-lib";
import searchMessage from "./components/search-message";
import searchResults from "./components/search-results";

const SEARCH_STATUS = {
  NOT_STARTED: "NOT_STARTED", // default state

  TEXT_SEARCHING: "TEXT_SEARCHING", // searching text and fetching categories
  // FILTER_SEARCHING: "FILTER_SEARCHING", // applying category - only for completeness, it is not implemented, skip immediatelly to next steps

  TEXT_ERROR: "TEXT_ERROR", // failed to fetch text and filters
  FILTER_ERROR: "FILTER_ERROR", // failed to apply filter, should be very rare

  TEXT_EMPTY_RESULT: "TEXT_EMPTY_RESULT",
  FILTER_EMPTY_RESULT: "FILTER_EMPTY_RESULT", // should not happen unless BE behaves unexpectedly

  TEXT_TOO_MANY_RESULTS: "TEXT_TOO_MANY_RESULTS",
  FILTER_TOO_MANY_RESULTS: "FILTER_TOO_MANY_RESULTS", // should not happen unless BE behaves unexpectedly

  SUCCESS: "SUCCESS", // showing search result (with or without filter)
};

// LEGAL STATE TRANSITIONS:
// NOT_STARTED ->             TEXT_SEARCHING
// TEXT_SEARCHING ->                         | TEXT_ERROR   | TEXT_EMPTY_RESULT   | TEXT_TOO_MANY_RESULTS   | SUCCESS
// (FILTER_SEARCHING) ->      TEXT_SEARCHING | FILTER_ERROR | FILTER_EMPTY_RESULT | FILTER_TOO_MANY_RESULTS | SUCCESS
// TEXT_ERROR ->              TEXT_SEARCHING
// FILTER_ERROR ->            TEXT_SEARCHING | (FILTER_SEARCHING)
// TEXT_EMPTY_RESULT ->       TEXT_SEARCHING
// FILTER_EMPTY_RESULT ->     TEXT_SEARCHING | (FILTER_SEARCHING)
// TEXT_TOO_MANY_RESULTS ->   TEXT_SEARCHING
// FILTER_TOO_MANY_RESULTS -> TEXT_SEARCHING | (FILTER_SEARCHING)
// SUCCESS ->                 TEXT_SEARCHING | (FILTER_SEARCHING)
// (all states can transition to TEXT_SEARCHING)

export default {
  name: "App",
  mixins: [translateMixin],
  components: {
    searchMessage,
    searchResults,
  },
  async mounted() {
    let historyState = getHistoryState();
    if (historyState) {
      this.comingFromHistory = true; // used to supress the default event from b2b-search-form

      this.appliedSearchText = this.initialSearchText = historyState.appliedSearchText;
      if (historyState.currentStatus === "TEXT_SEARCHING") {
        this.scopeIdPromise = getScopeIdOrThrow().then((scopeId) => (this.scopeId = scopeId));

        // A search was in progress when user left the page,
        // need to "restart" the search that was in progress when user left page
        this.getAllResultsForText(this.appliedSearchText);
      } else {
        // all other states are static, just restore all of the objects
        this.scopeIdPromise = Promise.resolve(this.scopeId);
        this.scopeId = historyState.scopeId;

        this.currentStatus = historyState.currentStatus;
        this.unfilteredResults = historyState.unfilteredResults;
        this.selectedCategories = historyState.selectedCategories;
        this.selectedPageSize = historyState.selectedPageSize;
        this.selectedPage = historyState.selectedPage;
      }
    } else {
      this.scopeIdPromise = getScopeIdOrThrow().then((scopeId) => (this.scopeId = scopeId));

      this.comingFromHistory = false;
      // search text coming from search form on another page (e.g. coming from dashboard)
      this.initialSearchText = getSearchText();
    }

    // no point in putting it earlier, all the things above are done in parallell with this anyway
    await translateSetup(["mybusiness"]);

    // shows everything, so this.initialSearchText must have been assigned first
    this.loadingTranslations = false;
  },
  data() {
    return {
      comingFromHistory: undefined,

      SEARCH_STATUS,
      loadingTranslations: true,
      scopeIdPromise: undefined, // eventually resolved to the scopeId value needed for searches
      scopeId: undefined, // the resolved value from scopeIdPromise, guaranteed to have a value if and only if a search has been completed
      initialSearchText: undefined,

      currentStatus: SEARCH_STATUS.NOT_STARTED,
      unfilteredResults: undefined,

      // NO! having this here in "data" makes it reactive, which breaks "cancel()"
      // In composition API, this would be like declaring it as null instead of ref(null).
      // backendCallCancelablePromise: undefined,

      queryCounter: 0, // to invalidate older threads

      appliedSearchText: undefined,

      // ['MOBILE', 'SERVICE', ...]. changed by user, reset to empty array at any allResults search
      // always ordered the same as the category list in the latest allResults search result
      selectedCategories: [],

      selectedPageSize: undefined,
      selectedPage: undefined,
    };
  },
  methods: {
    getCategoryDisplayName(id, count) {
      return `${getTranslatedSubCategoryDisplayName(id)} (${count})`;
    },
    async maybeTrackAndGetAllResultsForText(eventDetails) {
      if (!eventDetails.triggeredByDefault) {
        // normal case, i.e., user was on search page, and submitted a new search.

        // when user comes from another page (which should have done the submit tracking) or when
        // we are restoring an old search from browser history, triggeredByDefault will be true.
        analytics.trackSubmitValidSearch(eventDetails.text.length, "search page");
      }

      // get the search results, UNLESS we are recovering it from history instead.
      if (!(eventDetails.triggeredByDefault && this.comingFromHistory)) {
        this.getAllResultsForText(eventDetails.text);
      }
    },
    async getAllResultsForText(text) {
      if (
        this.backendCallCancelablePromise &&
        this.backendCallCancelablePromise.cancel && // the service may have returned a standard Promise
        !this.backendCallCancelablePromise.isCancelled
      ) {
        // previous query exists and is in progress, or resolved. cancel (tell backend to stop) before replacing it.
        this.backendCallCancelablePromise.cancel();
      }

      this.appliedSearchText = text;
      this.currentStatus = SEARCH_STATUS.TEXT_SEARCHING;
      this.saveState();

      let queryIndex = ++this.queryCounter;

      this.scopeIdPromise
        .then(() => {
          if (queryIndex === this.queryCounter) {
            return (this.backendCallCancelablePromise = getAllSubscriptionsForText(
              this.scopeId,
              text
            ));
          }
        })
        .then((backendResponse) => {
          if (queryIndex === this.queryCounter) {
            this.unfilteredResults = backendResponse;

            this.resetSelectedCategories();

            if (this.unfilteredResults.subscriptions.length > 0) {
              this.actOnFilters();
              // saveState is included by actOnFilters
            } else {
              this.currentStatus = SEARCH_STATUS.TEXT_EMPTY_RESULT;
              this.saveState();
            }

            analytics.trackSuccessSearchResults(this.unfilteredResults);
          }
        })
        .catch((error) => {
          if (queryIndex === this.queryCounter) {
            if (error && error.body && error.body.translationKey === "HIT_LIMIT_EXCEEDED") {
              this.currentStatus = SEARCH_STATUS.TEXT_TOO_MANY_RESULTS;
              analytics.trackTooManyResults(true);
            } else {
              this.currentStatus = SEARCH_STATUS.TEXT_ERROR;
              analytics.trackUnexpectedSearchError(true);
              if (error && error.status === 401) {
                logError(
                  "b2b-subscription-search",
                  "Failed to perform search, got 401 status (user has probably been logged out)"
                );
              } else {
                logError(
                  "b2b-subscription-search",
                  "Failed to perform search, other unexpected error"
                );
              }
            }
            this.saveState();
          }
        });
    },
    actOnFiltersWithTracking(changedProductCategory) {
      analytics.trackSubmitCategoryFilters(
        this.selectedCategories,
        changedProductCategory,
        this.selectedCategories.includes(changedProductCategory)
      );

      this.actOnFilters(() => {
        analytics.trackSuccessCategoryFilterResults(
          this.filteredResults ? this.filteredResults.subscriptions.length : 0
        );
      });
    },
    actOnFilters(optionalSuccessCallback) {
      try {
        this.currentStatus =
          this.filteredResults.subscriptions.length > 0
            ? SEARCH_STATUS.SUCCESS
            : SEARCH_STATUS.FILTER_EMPTY_RESULT;

        if (optionalSuccessCallback) {
          optionalSuccessCallback();
        }
      } catch (error) {
        this.currentStatus = SEARCH_STATUS.FILTER_ERROR;
        analytics.trackUnexpectedSearchError(false);
        logError("b2b-subscription-search", "Failed to apply filters");
      }
      this.saveState();
    },
    resetSelectedCategories() {
      this.selectedCategories = [];
    },
    changeSelectedCategories(changedCategory, checked) {
      this.selectedCategories = this.unfilteredResults.categories // all available categories
        .map((category) => category.productCategory) // convert "[{productCategory, searchHits}, ...]" to "[productCategory, ...]""
        .filter((category) =>
          checked
            ? // make array of only the categories previously selected by users, AND the changed one
              this.selectedCategories.includes(category) || changedCategory === category
            : // make array of only the categories previously selected by users, BUT NOT the changed one
              this.selectedCategories.includes(category) && changedCategory !== category
        );
    },
    saveSelectedPage(pageSize, page) {
      this.selectedPageSize = pageSize;
      this.selectedPage = page;
      this.saveState();
    },
    trackFailedValidation(event) {
      if (event.detail.triggeredByDefault) {
        // query came from a different page, BUT that page should have validated it and done it's own tracking
      } else {
        analytics.trackSubmitTooShortSearch(event.detail.text.length, "search page");
      }
    },
    saveState() {
      // save state to history so it can be restored if we go "back" from other page

      replaceHistoryState({
        scopeId: this.scopeId,
        currentStatus: this.currentStatus,
        unfilteredResults: this.unfilteredResults,
        appliedSearchText: this.appliedSearchText,
        selectedCategories: this.selectedCategories,
        selectedPageSize: this.selectedPageSize,
        selectedPage: this.selectedPage,
      });
    },
  },
  computed: {
    filteredResults() {
      return filterSubscriptionsByCategory(this.selectedCategories, this.unfilteredResults);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.form-section {
  background: $telia-gray-50;
  padding-top: $telia-spacing-32;
  padding-bottom: $telia-spacing-0;

  .telia-heading-skeleton {
    height: 5rem;
    width: 30%;
  }

  .telia-heading-skeleton,
  telia-heading {
    padding-bottom: $telia-spacing-16;
  }
}

.categories__heading {
  margin-bottom: $telia-spacing-16;
}
.categories__checkbox {
  margin-top: $telia-spacing-8;
}

.all-results-search-message__column,
.categories__column,
.search-results__column {
  padding-top: $telia-spacing-24;
  padding-bottom: $telia-spacing-24;
}
</style>
