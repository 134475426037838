<template>
  <div>
    <!--<telia-p>
      defaultPage: {{ defaultPage }}<br />
      defaultPageSize: {{ defaultPageSize }}<br />
      selectedPage: {{ selectedPage }}<br />
      selectedPageSize: {{ selectedPageSize }}<br />
      pageToShow: {{ pageToShow }}<br />
      pageSizeToShow: {{ pageSizeToShow }}<br />
    </telia-p>-->

    <telia-p variant="paragraph-200">
      {{
        t("searchResult.currentHits", {
          0: currentPageFirstItemIndex + 1,
          1: currentPageLastRealItemIndex + 1,
          2: searchResults.subscriptions.length,
        })
      }}</telia-p
    >
    <div v-for="(searchResult, index) in currentPageResults" :key="searchResult.id + '_' + index">
      <search-result
        :scope-id="scopeId"
        :search-value="searchText"
        :search-result="searchResult"
        :is-first="pageToShow === 1 && index === 0"
        :selected-categories="selectedCategories"
      />
    </div>
    <b2x-paginator
      :list-length="searchResults.subscriptions.length"
      :page-sizes="pageSizesString"
      :default-page-size="defaultPageSize"
      :page-number="defaultPage"
      @paginationChange="(event) => changePage(event.detail.page, event.detail.pageSize)"
    />
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import searchResult from "./search-result";
import { analytics } from "@telia/b2b-search-framework";

// the paginator page index is 1-based
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 25;
const PAGE_SIZES_STRING = "[25, 50, 100]";

export default {
  name: "SearchResults",
  mixins: [translateMixin],
  components: {
    searchResult,
  },
  emits: ["change-page"],
  props: {
    getParentScrollTargetCallback: {
      // would be fine with HTMLElement, but b2b-subscription-search has some issues with refs not being set until nextTick
      type: Function,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    searchText: {
      type: String,
      required: true,
    },
    searchResults: {
      type: Object,
      required: true,
    },
    selectedCategories: {
      type: Array,
      required: true,
    },
    defaultPageSize: {
      type: Number,
      default: DEFAULT_PAGE_SIZE,
      required: false,
    },
    defaultPage: {
      type: Number,
      default: DEFAULT_PAGE,
      required: false,
    },
  },
  data() {
    return {
      // changed when user switch page
      // undefined = user has not made selection, fall back to using the "default*****" props instead
      selectedPage: undefined,
      selectedPageSize: undefined,

      // the internal default values for b2x-paginator, but we have to know the values becuase it doesn't tell us until user change page
      pageSizesString: PAGE_SIZES_STRING,
    };
  },
  created() {
    translateSetup();
  },
  methods: {
    changePage(page, pageSize) {
      if (pageSize != this.pageSizeToShow) {
        analytics.trackChangePageSize(this.searchResults.subscriptions.length, pageSize);
      } else if (page != this.pageToShow) {
        analytics.trackChangePage(
          this.searchResults.subscriptions.length,
          this.isPageFirst(page),
          this.isPageLast(page, pageSize, this.searchResults.subscriptions.length)
        );
      }

      this.selectedPage = page;
      this.selectedPageSize = pageSize;

      // note: scrollIntoViewOptions is not supported by Safari
      // and using { behavior: smooth } in Firefox randomly stops half-way, probably because the size of the element changes during the scroll
      const parentScrollTarget = this.getParentScrollTargetCallback();
      if (parentScrollTarget) {
        this.getParentScrollTargetCallback().scrollIntoView(true);
      }

      this.$emit("change-page", { pageSize, page });
    },
    isPageFirst(page) {
      // first page is "1", not "0"
      return page === 1;
    },
    isPageLast(page, pageSize, itemCount) {
      // if the page an item that is, or is beyond, the last item in the list, it is the last page
      return this.getLastPotentialItemIndexOnPage(page, pageSize) >= itemCount - 1;
    },
    getLastPotentialItemIndexOnPage(page, pageSize) {
      // first element of "next" page -1
      // we don't know if this item actually exist
      return page * pageSize - 1;
    },
  },
  computed: {
    currentPageFirstItemIndex() {
      // first page is "1", not "0"
      return (this.pageToShow - 1) * this.pageSizeToShow;
    },
    currentPageLastRealItemIndex() {
      // the smallest of first element of "next" page -1, and the last index of all subscriptions
      return Math.min(
        this.getLastPotentialItemIndexOnPage(this.pageToShow, this.pageSizeToShow),
        this.searchResults.subscriptions.length - 1
      );
    },
    currentPageResults() {
      // slice out the current page
      // second param should be the first index "not" to include in the output, and an out of bounds value is allowed
      return this.searchResults.subscriptions.slice(
        this.currentPageFirstItemIndex,
        this.getLastPotentialItemIndexOnPage(this.pageToShow, this.pageSizeToShow) + 1
      );
    },
    pageToShow() {
      return this.selectedPage ?? this.defaultPage;
    },
    pageSizeToShow() {
      return this.selectedPageSize ?? this.defaultPageSize;
    },
  },
  watch: {
    "searchResults.subscriptions.length"() {
      // b2x-paginator resets page to 1 (without triggering event) when search result LENGTH change.
      // This acts as if we got such an event.
      // Replacing the result with one with the same length does NOT reset the page, so we should not emit an event in that case either.
      this.selectedPage = DEFAULT_PAGE;
      this.$emit("change-page", { pageSize: this.pageSizeToShow, page: DEFAULT_PAGE });
    },
  },
};
</script>

<style lang="scss"></style>
