import { getSearchResults } from "@telia/b2b-search-framework";

/**
 * Calls backend to execute a search based on text, with no filters applied.
 * @param {String} scopeId
 * @param {String} text
 * @returns {CancelablePromise}, will resolve {subscriptions: [...], categories: [...] },
 * OR reject an error (may have body.translationKey = "HIT_LIMIT_EXCEEDED")
 */
export const getAllSubscriptionsForText = (scopeId, text) =>
  getSearchResults(["ALL"], scopeId, text);

/**
 * Apply filters to a previous text-search.
 * @param {Array<string>} categories - e.g. ["MOBILE", "SERVICES"]. If empty, all results are given.
 * @param {Object} previousAllResults
 * @returns {Object}, {subscriptions: [...], categories: [...] },
 */
export const filterSubscriptionsByCategory = (categories, previousAllResults) => {
  // special case
  if (categories.length <= 0) {
    return previousAllResults;
  } else {
    return {
      subscriptions: previousAllResults.subscriptions.filter((subscription) =>
        categories.includes(subscription.productCategory)
      ),
      categories: previousAllResults.categories.filter((category) =>
        categories.includes(category.productCategory)
      ),
    };
  }
};
