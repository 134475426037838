<template>
  <article v-if="result" class="search-result">
    <!-- Search result heading -->
    <telia-heading tag="h3" variant="title-100" class="search-result__header">
      <!--
        Tracking is not 100%, this does not track "open in new page" etc
        Everyone was fine with this.
        More robust option would be to send all these clicks
        to a redirection page which does the tracking and then forwards to "actual" page
      -->
      <telia-link
        @click="trackClickAndNavigateToResult"
        :href="getDetailsLinkUrl(searchResult)"
        variant="navigation"
      >
        <search-result-heading
          :title="searchResult.title"
          :reference="searchResult.reference"
          :icon="getCategoryIcon(searchResult.productCategory)"
          ><span class="search-result__header_title"
            ><span v-html="getHighlightHtml(searchResult.title, searchValue)"></span
            ><span v-if="searchResult.title && searchResult.reference"> / </span
            ><span v-html="getHighlightHtml(searchResult.reference, searchValue)"></span></span
        ></search-result-heading>
        <telia-icon name="chevron-right" size="sm" />
      </telia-link>
    </telia-heading>

    <!-- Search result -->
    <div class="search-result__content">
      <div v-for="(item, value) in result.details" :key="value" class="search-result__content-item">
        <telia-p variant="paragraph-200"
          >{{ item.label }}: <span v-html="getHighlightHtml(item.value, searchValue)"></span
        ></telia-p>
      </div>
    </div>

    <!-- Search result components -->
    <div v-if="result.components.length > 0" class="search-result__components">
      <telia-row>
        <telia-col
          width="12"
          width-md="6"
          v-for="{ component, title, user } in result.components"
          :key="component.id"
          class="search-result__components-col"
          ><telia-p variant="paragraph-200">
            <search-result-heading :title="title" :reference="user"
              ><span class="search-result__header_title"
                ><span v-html="getHighlightHtml(title, searchValue)"></span
                ><span v-if="title && user"> / </span
                ><span
                  v-html="getHighlightHtml(user, searchValue)"
                ></span></span></search-result-heading
          ></telia-p>
          <div v-for="(item, index) in component" :key="index">
            <telia-p variant="paragraph-200"
              >{{ item.label }}: <span v-html="getHighlightHtml(item.value, searchValue)"></span
            ></telia-p></div></telia-col
      ></telia-row>
    </div>
  </article>
</template>

<script>
import { navigateToUrl } from "single-spa";
import { analytics } from "@telia/b2b-search-framework";
import { translateSetup, translateMixin } from "../locale";
import { mapSearchResult } from "../services/mappers/map-response";
import searchResultHeading from "./search-result-heading";
import { getDetailsPageUrl } from "@telia/b2b-subscription-common";
import { getCategoryIcon } from "@telia/b2b-product-categories-lib";

export default {
  name: "SearchResult",
  mixins: [translateMixin],
  components: {
    searchResultHeading,
  },
  props: {
    scopeId: {
      type: String,
      required: true,
    },
    searchValue: {
      type: String,
      required: true,
    },
    searchResult: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
    selectedCategories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      result: undefined,
    };
  },
  created() {
    translateSetup();
  },
  mounted() {
    this.filterEmptyValues();
  },
  methods: {
    async trackClickAndNavigateToResult(event) {
      event.preventDefault();

      await analytics.trackClickResult(
        this.isFirst,
        this.searchResult.productCategory,
        this.selectedCategories
      );

      navigateToUrl(this.getDetailsLinkUrl(this.searchResult));
    },
    filterEmptyValues() {
      this.result = mapSearchResult(this.searchResult, this.t);
    },
    getHighlightHtml(itemText, searchValue) {
      // the output is HTML, the input is TEXT.
      // If the TEXT contains something that looks like HTML, it will be treated like HTML = bug or security issue.
      // So first encode the TEXT as HTML, i.e. convert "<" to "&lt;"
      let hostElement = document.createElement("div");
      hostElement.textContent = itemText;
      let htmlEncodedValue = hostElement.innerHTML;

      let searchRegExpStr = this.escapeRegExp(searchValue);
      return htmlEncodedValue.replace(
        new RegExp(searchRegExpStr, "ig"),
        '<span class="search-highlight">$&</span>'
      );
    },
    escapeRegExp(str) {
      return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
    },
    getDetailsLinkUrl({ legacyUrl, id, productCategory }) {
      return getDetailsPageUrl(this.scopeId, id, productCategory, legacyUrl);
    },
    getCategoryIcon(id) {
      return getCategoryIcon(id);
    },
  },
};
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
.search-result {
  margin: $telia-spacing-24 0 $telia-spacing-32 0;

  &__header {
    background-color: $telia-gray-100;
    padding: $telia-spacing-24 $telia-spacing-24 $telia-spacing-12 $telia-spacing-24;
  }

  &__content {
    padding: $telia-spacing-12 $telia-spacing-24;

    &-item {
      list-style: none;
    }
  }

  &__components {
    padding: $telia-spacing-12 $telia-spacing-24;
    border-top: 1px solid $telia-gray-200;

    &-col {
      margin: $telia-spacing-12 0 $telia-spacing-24 0;
    }
  }
}

.search-highlight {
  background-color: $telia-green-300;
}
</style>
