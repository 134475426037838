// purpose of this module is mostly to be able to mock it easier in unit tests

import { logError } from "@telia/b2x-logging";

export const getHistoryState = () => history.state;

export const replaceHistoryState = (state) => {
  try {
    // Why JSON parse/stringify?
    // if we pass a Vue ProxyObject (i.e. the object directly from e.g. a prop)
    // browser fails to clone some complex objects for some reason.
    // There is "toRaw", but that only "unproxy" the outer layer of the object.
    // Cloning this way first turns it into raw normal objects that browser can handle.

    history.replaceState(
      JSON.parse(JSON.stringify(state)),
      "" // name of state, Safari use it but ignored by most browsers, is obsolete and "" is recommended according to MDN.
    );
  } catch (error) {
    // most browsers impose a limit in the MegaByte order of magnitute (1-16 MB)
    logError("b2b-subscription-search", "Failed to replace state (maybe too large?)");
  }
};
