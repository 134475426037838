<template>
  <div v-if="status === 'TEXT_SEARCHING'">
    <b2x-spinner size="large" class="results-section__spinner" />
    <telia-visually-hidden>{{ t("searchResult.inProgress") }}</telia-visually-hidden>
  </div>
  <div v-else-if="status === 'TEXT_ERROR' || status === 'FILTER_ERROR'">
    <telia-notification variant="inline" status="error" heading-tag="h2" html-aria-live="polite">
      <span slot="heading">
        <telia-visually-hidden>{{ t("searchError.status") }}</telia-visually-hidden>
        {{ t("searchError.title") }}</span
      >
      <telia-text-spacing slot="content">
        <telia-p>{{ t("searchError.content") }} </telia-p></telia-text-spacing
      >
    </telia-notification>
  </div>
  <div v-else-if="status === 'TEXT_EMPTY_RESULT' || status === 'FILTER_EMPTY_RESULT'">
    <telia-notification
      variant="inline"
      status="information"
      heading-tag="h2"
      html-aria-live="polite"
    >
      <span slot="heading">
        <telia-visually-hidden>{{ t("noResults.status") }}</telia-visually-hidden>
        {{ t("noResults.title") }}
      </span>
      <telia-text-spacing slot="content">
        <telia-p
          >{{
            status === "TEXT_EMPTY_RESULT"
              ? t("noResults.content.dueToText")
              : t("noResults.content.dueToTextOrFilter")
          }}
        </telia-p></telia-text-spacing
      >
    </telia-notification>
  </div>
  <div v-else-if="status === 'TEXT_TOO_MANY_RESULTS' || status === 'FILTER_TOO_MANY_RESULTS'">
    <telia-notification variant="inline" status="warning" heading-tag="h2" html-aria-live="polite">
      <span slot="heading">
        <telia-visually-hidden>{{ t("tooManyResults.status") }}</telia-visually-hidden>
        {{ t("tooManyResults.title") }}
      </span>
      <telia-text-spacing slot="content">
        <telia-p
          ><!-- backend will never give this when a category filtered search is possible -->
          {{ t("tooManyResults.content") }}
        </telia-p></telia-text-spacing
      >
    </telia-notification>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";

export default {
  name: "SearchResult",
  mixins: [translateMixin],
  components: {},
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  created() {
    translateSetup();
  },
};
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.results-section__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
