<template>
  <strong t-id="search-result-heading"
    ><telia-icon v-if="icon" class="search-result__header_icon" :name="icon" size="md" />
    <slot
      ><span class="search-result__header_title"
        >{{ title }}<span v-if="title && reference"> / </span>{{ reference }}</span
      >
    </slot></strong
  >
</template>

<script>
export default {
  name: "SearchResultHeading",
  props: {
    title: {
      type: String,
      required: true,
    },
    reference: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.search-result {
  &__header_icon {
    margin-right: $telia-spacing-8;
  }

  &__header_title {
    vertical-align: super;
  }
}
</style>
