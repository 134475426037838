import { translate as t } from "@telia/b2b-i18n";

const plainText = (value) => {
  if (value) {
    return value;
  } else {
    return null;
  }
};

const address = (value) => {
  if (!value) {
    return null;
  }

  const street = value.street || "-";
  const city = value.zipcode || value.city ? `, ${value.zipcode || "-"} ${value.city || "-"}` : "";
  const country = value.country ? ` ${value.country}` : "";

  return street + city + country;
};

const category = (value) => {
  if (!value) {
    return null;
  }

  return t(`categories.${value}`);
};

export default {
  plainText,
  address,
  category,
};
