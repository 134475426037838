import { h, createApp } from "vue";

import singleSpaVue from "single-spa-vue";
import "./set-public-path";

import App from "./b2b-subscription-search.vue";

import "@telia/b2b-search-form";
import "@telia/b2x-spinner";
import "@telia/b2x-card";
import "@telia/b2x-paginator";

// we don't use this directly, but b2b-search-form use it and we have to "provide" it
import "@telia/b2x-skeleton-input";

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecyle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
